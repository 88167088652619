<template>
  <BaseContainer>
    <LegacyHelp />
  </BaseContainer>
</template>

<script>
import LegacyHelp from '@components/Settings/LegacyHelp'

export default {
  page: {
    title: 'New Features'
  },

  components: {
    LegacyHelp
  }
}
</script>
