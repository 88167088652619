<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto sm:flex sm:justify-between px-6 space-x-4">
      <BaseCard class="sm:w-1/2">
        <section>
          <h2 class="text-lg">
            Jobs are being replaced by Assessments
          </h2>
          <p class="my-3">
            We understand that sometimes you're looking to Assess candidates or employees across multiple jobs or areas of your business. From now on, you'll be able to group them any way you wish with our Assessments page. Don't worry about the existing Jobs you've already set-up, these will automatically be migrated and can now be found on the Assessments page.
          </p>
          <h2 class="text-lg">
            We've changed how you invite candidates
          </h2>
          <p class="my-3">
            Previously, if you'd like to assess a candidate, you'd need to manually add them one by one. We've now created a public link that you can share anyway you like. Simply navigate to the Assessment you'd like to send, click on the invite link and you'll get a public link that you can share.
          </p>
          <p class="my-3">
            If you'd like to continue using the manual invite feature, you can still do so from 'The Invite Candidates by Email' page within the assessment.
          </p>
          <h2 class="text-lg">
            We have a new tests page
          </h2>
          <p class="my-3">
            Interested in finding out more about how specific tests, who they're aimed at and the competencies they assess? We've now added a Tests page that will provide all the information you need.
          </p>
        </section>
      </BaseCard>
      <BaseCard class="sm:w-1/2">
        <section>
          <h2 class="text-lg">
            Will you be able to see all your old test results?
          </h2>
          <p class="my-3">
            Absolutely, you'll be able to see all results just like before on the Results page which is in the profile dropdown in the top right. Soon we'll be providing enhanced data analytics and visualisations directly in the Assessments that will ultimately replace this page.
          </p>
          <h2 class="text-lg">
            Can I send different tests to candidates in the same Assessment?
          </h2>
          <p class="my-3">
            In order to make candidate inviting and comparison as simple as possible, the tests are locked at the assessment level. If you'd like to send different tests to one or more candidates, you can do this by setting up a new assessment. Setting up an assessment takes less than a minute.
          </p>
          <h2 class="text-lg">
            Will the API still work?
          </h2>
          <p class="my-3">
            Yes, any integrations you've already set-up with our platform will continue to work exactly as they did before.
          </p>
          <h2 class="text-lg">
            Will my plan change?
          </h2>
          <p class="my-3">
            No, as an existing client, your plan will be unaffected.
          </p>
        </section>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'

export default {
  components: {
    SettingsNavigation
  }
}
</script>
